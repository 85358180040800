import Vue from "vue";
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import 'buefy/dist/buefy.css'

import store from './store'
import router from './router'

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueResource);

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
