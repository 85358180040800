<template>
  <section class="section is-large">
    <h1 class="title">Se incarca...</h1>
    <h2 class="subtitle">
      Va rugam asteptati
    </h2>
  </section>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>

</style>