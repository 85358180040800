import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from './modules/auth'

const vuexPersist = new VuexPersist({
  key: 'administrare-primairii',
  storage: window.localStorage,
})

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    auth,
  },
  strict: debug,
})
