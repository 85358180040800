import store from "../store";

export default [
  {
    path: "/",
    name: "home",
    component: () => lazyLoadView(import("../components/Home")),
  },
  {
    path: "/login",
    name: "login",
    component: () => lazyLoadView(import("../components/auth/Login")),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/administrare",
    name: "administrare",
    component: () =>
      lazyLoadView(import("@/components/app/administrare/Administrare")),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "persoane-fizice",
        name: "administrare-persoane-fizice",
        alias: "persoane-fizice/:page",
        component: () =>
          lazyLoadView(import("@/components/app/administrare/PersoaneFizice")),
      },
      {
        path: "persoane-juridice",
        name: "administrare-persoane-juridice",
        alias: "persoane-juridice/:page",
        component: () =>
          lazyLoadView(
            import("@/components/app/administrare/PersoaneJuridice")
          ),
      },
      {
        path: "adauga-persoana-fizica",
        name: "adauga-persoana-fizica",
        component: () =>
          lazyLoadView(
            import("@/components/app/administrare/AdaugaPersoanaFizica")
          ),
      },
      {
        path: "adauga-persoana-juridica",
        name: "adauga-persoana-juridica",
        component: () =>
          lazyLoadView(
            import("@/components/app/administrare/AdaugaPersoanaJuridica")
          ),
      },
      {
        path: "editeaza-persoana/:id",
        name: "editeaza-persoana",
        component: () =>
          lazyLoadView(
            import("@/components/app/administrare/EditeazaPersoana")
          ),
      },
    ],
  },
  {
    path: "/registru-agricol",
    name: "registru-agricol",
    component: () =>
      lazyLoadView(import("@/components/app/registru/RegistruAgricol")),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/plati",
    name: "plati",
    component: () => lazyLoadView(import("@/components/app/plati/Plati")),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/taxe-impozite",
    name: "taxe-impozite",
    component: () => lazyLoadView(import("@/components/app/taxe/TaxeImpozite")),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/arhiva",
    name: "arhiva",
    component: () => lazyLoadView(import("@/components/app/arhiva/Arhiva")),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("../components/404").default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
];

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@/components/app/Loading").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@/components/app/Error").default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
