const envMapping = {
  "app.breasta.igeek.ro": "breasta",
  "breasta.igeek.ro": "breasta",
  "app.robanesti.igeek.ro": "robanesti",
  "robanesti.igeek.ro": "robanesti",
  localhost: "local",
};

export const environmentConfigs = {
  local: {
    title: "Primarie Demo",
    api_url: "http://localhost:8000",
    environmentType: "development",
    modules: {
      registru_agricol: true,
      arhiva: true,
      taxe_si_impozite: true,
      plati: true,
    },
  },
  breasta: {
    title: "Primaria Breasta",
    api_url: "https://breasta.igeek.ro",
    environmentType: "production",
    modules: {
      registru_agricol: true,
      arhiva: false,
      taxe_si_impozite: false,
      plati: false,
    },
  },
  robanesti: {
    title: "Primaria Robanesti",
    api_url: "https://robanesti.igeek.ro",
    environmentType: "production",
    modules: {
      registru_agricol: true,
      arhiva: false,
      taxe_si_impozite: false,
      plati: false,
    },
  },
};

export const env = envMapping[window.location.hostname] || "local";
// export const env = process.env.VUE_APP_TENANT || 'local'; // todo: bring this back with zip builds or folder builds with env in name

export const currentEnvironmentConfig = environmentConfigs[env];

export const isProduction = () => {
  return currentEnvironmentConfig.environmentType !== "development";
};

// @todo move this to a dedicated helper
export function log(...args) {
  !isProduction() && arguments.length && console.log("UI LOG:", ...args);
}
