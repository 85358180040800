<template>
  <b-navbar class="is-light">
    <!--    <template #brand>-->
    <!--      <b-navbar-item tag="router-link" :to="{ path: '/' }">-->
    <!--        <img-->
    <!--            src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"-->
    <!--            alt="Lightweight UI components for Vue.js based on Bulma"-->
    <!--        >-->
    <!--      </b-navbar-item>-->
    <!--    </template>-->
    <template #start>
      <router-link tag="b-navbar-item" to="/">
        {{ environmentConfig.title }}
      </router-link>
      <b-navbar-dropdown label="Administrare" v-if="isLoggedIn">
        <router-link tag="b-navbar-item" to="/administrare/persoane-fizice">
          Persoane Fizice
        </router-link>
        <router-link tag="b-navbar-item" to="/administrare/persoane-juridice">
          Persoane Juridice
        </router-link>
      </b-navbar-dropdown>
      <router-link tag="b-navbar-item" to="/registru-agricol"
                   v-if="isLoggedIn && !!(environmentConfig.modules && environmentConfig.modules.registru_agricol)">
        Reg. Agricol
      </router-link>
      <router-link tag="b-navbar-item" to="/taxe-impozite"
                   v-if="isLoggedIn && !!(environmentConfig.modules && environmentConfig.modules.taxe_si_impozite)">
        Taxe si Impozite
      </router-link>
      <router-link tag="b-navbar-item" to="/arhiva"
                   v-if="isLoggedIn && !!(environmentConfig.modules && environmentConfig.modules.arhiva)">
        Arhiva Documente
      </router-link>
      <router-link tag="b-navbar-item" to="/plati"
                   v-if="isLoggedIn && !!(environmentConfig.modules && environmentConfig.modules.plati)">
        Plati On-Line
      </router-link>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <span class="pr-3" v-if="isLoggedIn">Autentificat ca <b>{{ user.name }}</b></span>
        <div class="buttons">
          <b-button class="is-danger is-light" @click="doLogout" v-show="isLoggedIn">
            Log Out
          </b-button>
          <router-link tag="b-button" class="button=" v-show="!isLoggedIn" to="/login">
            Autentificare
          </router-link>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import { currentEnvironmentConfig } from "@/config/tenants";

export default {
  name: "Navbar",

  data() {
    return {
      environmentConfig: currentEnvironmentConfig,
    };
  },

  methods: {
    doLogout() {
      this.$buefy.toast.open({
        message: "Delogare cu succes!",
        type: "is-danger",
      });
      this.logout();
      router.push("/");
    },

    ...mapActions({
      logout: "auth/logout",
    }),
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isAuthenticated",
      user: "auth/getUser",
    }),
  },
};
</script>

<style scoped>

</style>