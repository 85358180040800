<template>
  <section class="section is-large">
    <h1 class="title">Eroare</h1>
    <h2 class="subtitle">
      S-a produs o eroare. Daca problema se repeta, va rugam contactati un administrator de sistem.
    </h2>
  </section>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>