import { api, login } from "../../network";

// initial state
const state = () => ({
  token: "",
  user: null,
});

// getters
const getters = {
  getToken(state) {
    return state.token;
  },

  getUser(state) {
    return state.user;
  },

  isAuthenticated(state) {
    return !!state.token;
  },
};

// actions
const actions = {
  async login({ commit }, loginDetails) {
    try {
      const response = await login(loginDetails);

      if (response.data.token && response.data.user) {
        const token = response.data.token.split("|")[1];
        commit("setToken", token);
        commit("setUser", response.data.user);
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  },

  async validate() {
    return api
      .get("user")
      .then((response) => {
        return response?.data?.user;
      })
      .catch(() => {
        return false;
      });
  },

  logout({ commit }) {
    commit("setToken", "");
    commit("setUser", null);
  },
};

// mutations
const mutations = {
  setToken(state, token) {
    state.token = token;
    api.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : "Bearer None";
  },

  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
