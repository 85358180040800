<template>
  <div id="app">
    <Navbar/>

    <div class="container is-fullwidth p-5" id="mainView">

      <router-view></router-view>

    </div>

    <Footer/>
  </div>
</template>

<script>
import Navbar from "./components/layout/Navbar.vue";
import Footer from "./components/layout/Footer.vue";
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  computed: {
  ...mapGetters({
      token: 'auth/getToken'
    }),
  },
  mounted() {
    // initialize api authorization
    this.token && this.$store.commit('auth/setToken', this.token);
  }
};
</script>

<style>
#mainView {
  min-height: 500px;
}
</style>
