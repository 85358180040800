<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Copyright &copy; 2021 Web Edge Creative <br>
      </p>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer",
}
</script>

<style scoped>

</style>