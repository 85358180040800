import axios from "axios";
import { currentEnvironmentConfig } from "@/config/tenants";
import store from "@/store";

const base_url = currentEnvironmentConfig.api_url;
const api_base_url = `${base_url}/api`;

export const api = axios.create({
  baseURL: api_base_url,
});

api.defaults.headers.common["Authorization"] = "Bearer None";

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export const login = (loginDetails) => {
  return api.post(
    "token",
    Object.assign(loginDetails, { device_name: "webApp" })
  );
};

// @todo make functions for all api calls to avoid code duplication
